import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl} from 'react-intl';
import Modal from '../../containers/noheader-modal.jsx';

import styles from './select-school-ui.css';
import PokemonstartkitLogo from '../first-ui-library/pokemonstartkit-logo.jsx';
import PokemonstartkitButtonComponent, {PokemonstartkitSmallButtonComponent} from '../first-ui-library/pokemonstartkit-button/pokemonstartkit-button.jsx';
import SearchSelectBox from './select-search.jsx';

const submitButtonName = 'submitButton';

const prefDropDownId = 'dropdown-pref';
const cityDropDownId = 'dropdown-city';
const schoolDropDownId = 'dropdown-school';

class SelectSchoolUIComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',

            'handleSelectCity',
            'handleSelectSchool',
            'handleExport',
            'handleSelectPrefecture',
            'handleChangeSelectMode',
            'handleOpenPage'
        ]);
        

    }


    handleClose () {
        this.props.onRequestClose();
    }


    handleSelectPrefecture (e) {
        if (this.props.isTextInputMode || !this.props.isSchoolTeacher) {
            this.props.onSelectPrefTextMode(e);
        } else {
            this.props.onSelectPref(e);
        }
    }

    handleSelectCity (e) {
        this.props.onSelectCity(e);
    }

    handleSelectSchool (e) {
        this.props.onSelectSchool(e);
    }

    handleChangeSelectMode () {
        this.props.onChangeSelectMode();
    }

    handleExport () {
        this.props.onExport();
    }

    handleOpenPage (){
        this.props.onSelectOpenPage();
    }

    render () {
        return (
            <>
                <Modal
                    fullScreen
                    contentLabel={this.props.title}
                    id={this.props.id}
                    onRequestClose={this.handleClose}
                    isShowingBackButton={this.props.isShowingBackButton}
                    header
                >
                    <PokemonstartkitLogo />
                    <div className={styles.messageWrapper} >
                        <p><ruby>必要事項<rt>ひつようじこう</rt></ruby>を<ruby>入力<rt>にゅうりょく</rt></ruby>してください</p>
                    </div>
                   
                    <div className={styles.bodyWrapper}>
                        <div
                            id={prefDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            <p><ruby>都道府県<rt>とどうふけん</rt></ruby></p>
                            <SearchSelectBox
                                id={'prefNameSelector'}
                                onChangeValue={this.handleSelectPrefecture}
                                options={
                                    this.props.prefDatas.map(item => ({
                                        id: item.prefName,
                                        value: item.prefCode.toString(),
                                        label: item.prefName
                                    }))
                                }
                                compareText={this.props.prefName}
                            />
                        </div>
                        <div
                            id={cityDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            <p><ruby>市区町村<rt>しくちょうそん</rt></ruby></p>
                            {
                                (this.props.isTextInputMode || !this.props.isSchoolTeacher) ?
                                    <input
                                        type="text"
                                        className={styles.textInputSchoolname}
                                        onChange={this.props.onSetCityName}
                                        placeholder="市区町村名を入力(20文字まで)"
                                        maxLength="20"
                                    /> :
                                    <SearchSelectBox
                                        id={'cityNameSelector'}
                                        onChangeValue={this.handleSelectCity}
                                        options={this.props.cityOptions}
                                        disabled={!(this.props.prefName && this.props.isShowingCity)}
                                    />

                            }
                        </div>
                        <div
                            id={schoolDropDownId}
                            className={styles.selectSchoolModal}
                        >
                            {
                                this.props.isSchoolTeacher ?
                                    <p><ruby>小学校名<rt>しょうがっこうめい</rt></ruby></p> :
                                    <p><ruby>施設名<rt>しせつめい</rt></ruby></p>
                            }
                            
                            {
                                (this.props.isTextInputMode || !this.props.isSchoolTeacher) ?
                                    <input
                                        type="text"
                                        className={styles.textInputSchoolname}
                                        onChange={this.props.onSetSchoolName}
                                        placeholder={
                                            this.props.isSchoolTeacher ?
                                                '小学校名を入力(64文字まで)' :
                                                '施設名を入力(64文字まで)'
                                        }
                                        maxLength="64"
                                    /> :
                                    <>
                                        <SearchSelectBox
                                            id={'schoolNameSelector'}
                                            onChangeValue={this.handleSelectSchool}
                                            options={this.props.schoolOptions}
                                            disabled={!(this.props.cityName && this.props.isShowingSchool)}
                                        />
                                    </>
                            }
                        </div>
                        {
                            !this.props.isSchoolTeacher &&
                                <div className={styles.userSendAlertWrapper}>
                                    <span className={styles.userSendAlert}>
                                        小学校の方はこの画面から送信しないでください。小学校の方の入力ページは
                                    </span>
                                    <span
                                        className={styles.userSendAlertUrl}
                                        onClick={this.props.onChangeToSchoolTeacher}
                                    >こちら
                                    </span>
                                </div>
                        }
                        {
                            false &&
                            <div className={styles.errorMessageWrapper}>
                                <span
                                    className={styles.errorSendingMessage}
                                >
                                    何度も通信エラーが発生する方は
                                </span>
                                <span
                                    className={styles.errorSendingMessageUrl}
                                    onClick={this.handleOpenPage}
                                >
                                    こちら
                                </span>
                                <span
                                    className={styles.errorSendingMessage}
                                >
                                    (別タブでFAQページを開きます)
                                </span>
                            </div>
                        }
                        <div className={styles.sendBlock}>
                            {
                                this.props.isAvailableSubmittion && this.props.cityName != '' && this.props.prefName != '' && this.props.schoolName != '' ?
                                    <PokemonstartkitButtonComponent
                                        id={submitButtonName}
                                        onClick={this.handleExport}
                                        enabled
                                    > <ruby>送信<rt>そうしん</rt></ruby></PokemonstartkitButtonComponent> :
                                    <PokemonstartkitButtonComponent
                                        id={submitButtonName}
                                    > <ruby>送信<rt>そうしん</rt></ruby></PokemonstartkitButtonComponent>
                            }
                        </div>
                        {
                            this.props.isSchoolTeacher &&
                            (
                                this.props.isTextInputMode ?
                                    <PokemonstartkitSmallButtonComponent
                                        id={'noschoolButtonName'}
                                        enabled
                                        onClick={this.handleChangeSelectMode}
                                        reversalMode
                                    >
                                        <ruby>学校選択<rt>がっこうせんたく</rt></ruby>に<ruby>戻<rt>もど</rt></ruby>る
                                    </PokemonstartkitSmallButtonComponent> :
                                    <PokemonstartkitSmallButtonComponent
                                        id={'noschoolButtonName'}
                                        onClick={this.handleChangeSelectMode}
                                        enabled
                                        reversalMode
                                    >
                                        <ruby>選択肢<rt>せんたくし</rt></ruby>が<ruby>見<rt>み</rt></ruby>つからない<ruby>場合<rt>ばあい</rt></ruby>
                                    </PokemonstartkitSmallButtonComponent>
                            )
                        }


                    </div>

                    {
                        this.props.isSchoolTeacher && !this.props.isTextInputMode &&
                            <div className={styles.refference}>
                                <ruby>市区町村<rt>しくちょうそん</rt></ruby>データの<ruby>出典<rt>しゅってん</rt></ruby>：
                                <ruby>総務省<rt>そうむしょう</rt></ruby>「<ruby>都道府県<rt>とどうふけん</rt></ruby>コード
                                <ruby>及<rt>およ</rt></ruby>び<ruby>市区町村<rt>しくちょうそん</rt></ruby>コード」(
                                <a href='https://www.soumu.go.jp/denshijiti/code.html'>https://www.soumu.go.jp/denshijiti/code.html</a>
                                )を<ruby>加工<rt>かこう</rt></ruby>して<ruby>作成<rt>さくせい</rt></ruby>
                            </div>
                    }
                </Modal>

            </>
        );
    }
}

SelectSchoolUIComponent.propTypes = {
    prefDatas: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    onRequestClose: PropTypes.func,

    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    title: PropTypes.object.isRequired,
    isShowingBackButton: PropTypes.bool,

    onSelectCity: PropTypes.func,
    onSelectSchool: PropTypes.func,
    onSelectPref: PropTypes.func,
    onSelectPrefTextMode: PropTypes.func,
    onChangeSelectMode: PropTypes.func,


    onExport: PropTypes.func,

    onSetCityName: PropTypes.func,
    onSetSchoolName: PropTypes.func,

    isTextInputMode: PropTypes.bool,

    schoolName: PropTypes.string,
    cityName: PropTypes.string,
    prefName: PropTypes.string,

    isShowingCity: PropTypes.bool,
    isShowingSchool: PropTypes.bool,

    isAvailableSubmittion: PropTypes.bool,

    cityOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string
        })
    ),
    schoolOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
            extra: PropTypes.string
        })
    ),
    isSchoolTeacher: PropTypes.bool,
    onChangeToSchoolTeacher: PropTypes.func,
    isErrorSending: PropTypes.bool,

    onSelectOpenPage: PropTypes.func

};

export default injectIntl(SelectSchoolUIComponent);
